export const addAccount = data => {
  return dispatch => dispatch({ type: "ADD_ACCOUNT", data })
}

export const emptyAccount = () => {
  return dispatch => dispatch({ type: "EMPTY_ACCOUNT" })
}

export const changeAccount = (data) => {
  return dispatch => dispatch({ type: "CHANGE_ACCOUNT", data })
}
