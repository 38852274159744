export const addLogin = data => {
  return dispatch => dispatch({ type: "ADD_LOGIN", data })
}

export const emptyLogin = () => {
  return dispatch => dispatch({ type: "EMPTY_LOGIN" })
}

export const changeLogin = (data) => {
  return dispatch => dispatch({ type: "CHANGE_LOGIN", data })
}
