import React, { Component } from "react"
import {
  Navbar, Alert, Button, Badge, Row, Col, Card, CardBody } from "reactstrap"
import { connect } from "react-redux"
import { history } from "../../../history"
import classnames from "classnames"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import { emptyAccount, addAccount } from "../../../redux/actions/account"
import * as crud from '../../../services/crud'
import { AlertCircle } from "react-feather"

class ThemeNavbar extends Component {

  state = {
    loading: true,
    account: {}
  }

  componentDidMount() {
    this.getAccount();
  }

  getAccount = async () => {
    try {
      let account = await crud.post('account/one', { _id: this.props.organization.account });
      if (account.status == 200) {
        this.setState({
          account: account.data[0],
          loading: false
        });
        await this.props.addAccount(account.data[0]);
      }
    } catch (error) {

    }
  }

  render() {

    const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
    const navbarTypes = ["floating", "static", "sticky", "hidden"];

    return (
      <React.Fragment>
        <div className="content-overlay" />
        <div className="header-navbar-shadow" />
        {!this.state.loading && this.state.account.plan.type === 0 && //this.state.account.plan.testperiod <= 7 &&
        <Navbar className="d-flex navbar bg-warning px-2 text-white justify-content-center flex-nowrap">
          <Badge color="light-warning" className="mr-50">Atenção</Badge>
          <p className="mb-0 text-white">Faltam apenas {this.state.account?.plan?.testperiod ? this.state.account.plan.testperiod : 0} dias para o término do período de teste.</p>
        </Navbar>}

        <Navbar
          className={classnames(
            "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
            {
              "navbar-light": this.props.navbarColor === "default" || !colorsArr.includes(this.props.navbarColor),
              "navbar-dark": colorsArr.includes(this.props.navbarColor),
              "bg-primary":
                this.props.navbarColor === "primary" && this.props.navbarType !== "static",
              "bg-danger":
                this.props.navbarColor === "danger" && this.props.navbarType !== "static",
              "bg-success":
                this.props.navbarColor === "success" && this.props.navbarType !== "static",
              "bg-info":
                this.props.navbarColor === "info" && this.props.navbarType !== "static",
              "bg-warning":
                this.props.navbarColor === "warning" && this.props.navbarType !== "static",
              "bg-dark":
                this.props.navbarColor === "dark" && this.props.navbarType !== "static",
              "d-none": this.props.navbarType === "hidden" && !this.props.horizontal,
              "floating-nav":
                (this.props.navbarType === "floating" && !this.props.horizontal) || (!navbarTypes.includes(this.props.navbarType) && !this.props.horizontal),
              "navbar-static-top":
                this.props.navbarType === "static" && !this.props.horizontal,
              "fixed-top": this.props.navbarType === "sticky" || this.props.horizontal,
              "scrolling": this.props.horizontal && this.props.scrolling

            }
          )}
        >
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              <div
                className="navbar-collapse d-flex justify-content-between align-items-center"
                id="navbar-mobile"
              >
                <div className="bookmark-wrapper">
                  <NavbarBookmarks
                    sidebarVisibility={this.props.sidebarVisibility}
                    handleAppOverlay={this.props.handleAppOverlay}
                  />
                </div>
                {this.props.horizontal ? (
                  <div className="logo d-flex align-items-center">
                    {/*inserir nossa logo aqui*/}
                    {/*<div className="brand-logo mr-50"></div>*/}
                    <h2 className="text-primary brand-text mb-0">RDObras</h2>
                  </div>
                ) : null}

                <NavbarUser
                  handleAppOverlay={this.props.handleAppOverlay}
                  changeCurrentLang={this.props.changeCurrentLang}
                  userName="John Doe"
                  userImg={'https://www.construtoracesconetto.com.br/wp-content/uploads/2020/03/blank-profile-picture-973460_640.png'}
                />
              </div>
            </div>
          </div>
        </Navbar>
      </React.Fragment>
    )
  }
}


const mapStateToProps = state => {
  return {
    organization: state.organization.organization,
    account: state.account
  }
}

export default connect(
  mapStateToProps,
  { addAccount, emptyAccount }
)(ThemeNavbar)
