import React, { Component } from "react"
import { X, AlignJustify } from "react-feather"
import classnames from "classnames"
import { connect } from "react-redux"
import { changeLogin } from "../../../../redux/actions/auth"
import icon from "../../../../assets/img/logo/logo-rdo.png"
import rdobras from "../../../../assets/img/logo/logo-bras.png"

class SidebarHeader extends Component {
  render() {
    let {
      toggleSidebarMenu,
      activeTheme,
      collapsed,
      toggle,
      sidebarVisibility,
      menuShadow
    } = this.props
    return (
      <div className="navbar-header pl-1">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <div className="navbar-brand">
              {/*<NavLink to="/dashboard" className="navbar-brand">*/}
                <img src={icon} width="53" />
                <p className="brand-text mb-0 p-0">
                  {/*this.props.organization.dashboardName ? this.props.organization.dashboardName : 'ObraVerde'*/}
                  {/*Arpya{this.props.account.account && this.props.account.account.companySector === "industry" ? <small style={{ fontSize: 14 }}>Industry</small> : <small style={{ fontSize: 14 }}>Construction</small>}*/}
                  <img src={rdobras} width="110" />
                </p>
              {/*</NavLink>*/}
            </div>
          </li>
          <li className="nav-item nav-toggle">
            <div className="nav-link modern-nav-toggle">
              {collapsed === false ? (
                <AlignJustify
                  onClick={() => {
                    toggleSidebarMenu(true)
                    toggle()
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4",
                    {
                      "text-primary": activeTheme === "primary",
                      "text-success": activeTheme === "success",
                      "text-danger": activeTheme === "danger",
                      "text-info": activeTheme === "info",
                      "text-warning": activeTheme === "warning",
                      "text-dark": activeTheme === "dark"
                    }
                  )}
                  size={20}
                  style={{ marginTop: 3 }}
                  data-tour="toggle-icon"
                />
              ) : (
                <AlignJustify
                  onClick={() => {
                    toggleSidebarMenu(false)
                    toggle()
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4",
                    {
                      "text-primary": activeTheme === "primary",
                      "text-success": activeTheme === "success",
                      "text-danger": activeTheme === "danger",
                      "text-info": activeTheme === "info",
                      "text-warning": activeTheme === "warning",
                      "text-dark": activeTheme === "dark"
                    }
                  )}
                  size={20}
                  style={{ marginTop: 3 }}
                />
              )}
              <X
                onClick={sidebarVisibility}
                className={classnames(
                  "toggle-icon icon-x d-block d-xl-none font-medium-4",
                  {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark"
                  }
                )}
                size={20}
              />
            </div>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false
          })}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.login,
    organization: state.organization.organization,
    account: state.account
  }
}

export default connect(
  mapStateToProps,
  {
    changeLogin
  }
)(SidebarHeader);
