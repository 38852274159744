import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Monitor size={20} />,
    permissions: ["adm"],
    navLink: "/dashboard"
  },
  {
    id: "projects",
    title: "Obras",
    type: "item",
    icon: <Icon.Trello size={20} />,
    permissions: ["adm"],
    navLink: "/projects"
  },
  {
    id: "quote",
    title: "Orçamentos",
    type: "item",
    icon: <Icon.Briefcase size={20} />,
    permissions: ["adm"],
    navLink: "/quote"
  },
  {
    id: "employees",
    title: "Dpto. Pessoal",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["adm"],
    navLink: "/employees"
  },
  {
    id: "tools",
    title: "Ferram / Equip",
    type: "item",
    icon: <Icon.Tool size={20} />,
    permissions: ["adm"],
    navLink: "/tools"
  },
  {
    id: "products",
    title: "Produtos",
    type: "item",
    icon: <Icon.Box size={20} />,
    permissions: ["adm"],
    navLink: "/products"
  },
  {
    id: "services",
    title: "Serviços",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["adm"],
    navLink: "/services"
  },
  /*{
    id: "parameters",
    title: "Parâmetros",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["adm"],
    navLink: "/parameters"
  },*/
  {
    id: "my-account",
    title: "Gerenciar Conta",
    icon: <Icon.EyeOff size={20} />,
    type: "item",
    permissions: ["adm"],
    navLink: "/my-account",
    disabled: true,
    hidden: true
  },
  {
    id: "other-accounts",
    title: "Gerencia Usuários",
    icon: <Icon.EyeOff size={20} />,
    type: "item",
    permissions: ["adm"],
    navLink: "/other-accounts",
    disabled: true,
    hidden: true
  },
]

export default horizontalMenuConfig
