import { setJsonLocalStorage, getJsonLocalStorage } from "../../../utility/functions/Functions"

const INIT_STATE_ACCOUNT = getJsonLocalStorage('@obr:account');

const setData = async (state) => {
  setJsonLocalStorage('@obr:account', state);
}

export const account = (state = INIT_STATE_ACCOUNT, action) => {
  switch (action.type) {
    case 'ADD_ACCOUNT':
      state = action.data;
      setData(state);
      return state
    case 'EMPTY_ACCOUNT':
      state = {};
      setData(state);
      return state
    case 'CHANGE_ACCOUNT':
      state = { ...state, ...action.data };
      setData(state);
      return state
    default:
      return state;
  }
}
