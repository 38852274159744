import React from "react"
import ScrollToTop from "react-scroll-up"
import { Button } from "reactstrap"
import { Coffee, ArrowUp } from "react-feather"
import classnames from "classnames"

const Footer = props => {
  let footerTypeArr = ["sticky", "static", "hidden"]
  return (
    <footer
      style={{ zIndex: 0 }}
      className={classnames("footer footer-light", {
        "footer-static": props.footerType === "static" || !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden"
      })}
    >
      <p className="mb-1 clearfix">
        <span className="float-md-left d-block d-md-inline-block mt-25">
          🚀 Powered by
          <a
            href="https://f2tecnologia.digital"
            target="_blank"
            rel="noopener noreferrer"
          >
            F2 Tecnologia
          </a>
        </span>
        {/*<span className="float-md-right d-none d-md-block">
          <span className="align-middle">Feito com muito</span>{" "}
          <Coffee style={{ color: 'brown' }} size={20} />
        </span>*/}
      </p>
      {props.hideScrollToTop === false ? (
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      ) : null}
    </footer>
  )
}

export default Footer
