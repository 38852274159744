import { setJsonLocalStorage, getJsonLocalStorage } from "../../../utility/functions/Functions"

const INIT_STATE_ORGANIZATION = getJsonLocalStorage('@obr:organization');

const setData = async (state) => {
  setJsonLocalStorage('@obr:organization', state);
}

export const organization = (state = INIT_STATE_ORGANIZATION, action) => {
  switch (action.type) {
    case 'ADD_ORGANIZATION':
      state = Object.assign({}, state, action.data);
      setData(state);
      return state
    case 'EMPTY_ORGANIZATION':
      state = {};
      setData(state);
      return state
    case 'CHANGE_ORGANIZATION':
      state = { ...state, ...action.data };
      setData(state);
      return state
    default:
      return state;
  }
}
