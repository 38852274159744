import { setJsonLocalStorage, getJsonLocalStorage } from "../../../utility/functions/Functions"

const INIT_STATE = getJsonLocalStorage('@obr:profile');

const setData = async (state) => {
  setJsonLocalStorage('@obr:profile', state);
}

export const login = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'ADD_LOGIN':
      state = Object.assign({}, state, action.data);
      setData(state);
      return state
    case 'EMPTY_LOGIN':
      state = {};
      setData(state);
      return state
    case 'CHANGE_LOGIN':
      state = { ...state, ...action.data };
      setData(state);
      return state
    default:
      return state;
  }
}
