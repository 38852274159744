import React from "react"
import themeConfig from "../configs/themeConfig"
import classnames from "classnames"

const FullPageLayout = ({ children, ...rest }) => {
  return (
    <div
      className={classnames(
        "full-layout wrapper blank-page dark-layout",
        {
          "layout-dark": themeConfig.layoutDark
        }
      )}
      style={{ backgroundColor: "#f1f1f1" }}
    >
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            {/*<div className="flexbox-container">*/}
            <div style={{
              minHeight: 100 + 'vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}>
              <main
                className="main w-100"
              >
                {children}
              </main>
            </div>
            {/*</div>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullPageLayout
