import React from "react"
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
  Spinner
} from "reactstrap"
import { connect } from "react-redux"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
import * as utility from '../../../utility/functions/Functions'
import classnames from "classnames"
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
import { history } from "../../../history"
import { emptyLogin } from "../../../redux/actions/auth"
import FittedImage from 'react-fitted-image'
import ImageDefault from "../../../assets/img/pages/image_default.jpeg"
import ModalSchedule from "../../../../src/views/modal/Schedule"


const UserDropdown = props => {
  return (
    <DropdownMenu right style={{ minWidth: 200 }}>
      <DropdownItem tag="a" onClick={() => history.push('/my-account')}>
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Minha Conta</span>
      </DropdownItem>
      {(props.user.role && (props.user.role.includes('adm') || props.user.role.includes('mas') || props.user.role.includes('manage-users'))) &&
      <DropdownItem tag="a" onClick={() => history.push('/other-accounts')}>
        <Icon.Users size={14} className="mr-50" />
        <span className="align-middle">Gerenciar Usuários</span>
      </DropdownItem>}
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="#"
        onClick={() => props.logout()}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Sair da Conta</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

const HelpDropdown = props => {
  return (
    <DropdownMenu right style={{ minWidth: 200 }}>
      <DropdownItem tag="a" target="_blank" href="https://rdobras.notion.site/Guia-Inicial-RDObras-110dcfa4a59c4941951c2957f4380ae0">
        <Icon.BookOpen size={14} className="mr-50" />
        <span className="align-middle">Guia de Implantação</span>
      </DropdownItem>
      <DropdownItem tag="a" target="_blank" href="https://rdobras.tawk.help/category/trilhas-de-conhecimento-rdobras">
        <Icon.Play size={14} className="mr-50" />
        <span className="align-middle">Vídeos</span>
      </DropdownItem>
      <DropdownItem tag="a" target="_blank" href="https://rdobras.tawk.help/">
        <Icon.FileText size={14} className="mr-50" />
        <span className="align-middle">Artigos</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="javascript:void(Tawk_API.toggle())"
      >
        <Icon.MessageCircle size={14} className="mr-50" />
        <span className="align-middle">Chat</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
    modalSchedule: false,
  }

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult })
    })
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  logout = async () => {
    try {
      history.push('/');
      await localStorage.clear();
      await this.props.emptyLogin();
    } catch (error) {
      console.log('error');
    }
  }

  handleToggleModal = async () => {
    this.setState({
      modalSchedule: !this.state.modalSchedule
    });
  }

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex">
              <span style={{ marginTop: '0.8rem' }} className="nav-link-search">
                <Icon.HelpCircle size={16} /> <span className="user-status">Central de Ajuda</span>
              </span>
            </div>
          </DropdownToggle>
          <HelpDropdown logout={this.logout} {...this.props} />
        </UncontrolledDropdown>
        {/*<NavItem className="nav-search">
          <a href="javascript:void(Tawk_API.toggle())" style={{ margin: '1.4rem 1rem 1.35rem' }} className="nav-link-search">
            <Icon.Bell size={20} />
          </a>
        </NavItem>*/}
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.user.name && this.props.user.name.toUpperCase()}
              </span>
              <span className="user-status">Seja Bem-vindo(a)</span>
            </div>
            <span data-tour="user">
              {this.props.user.image &&
              <FittedImage
                className="round"
                fit="cover"
                style={{ width: 40, height: 40 }}
                loader={<Spinner />}
                src={this.props.user.image.base64 || this.props.user.image.path || ImageDefault}
              />}
            </span>
          </DropdownToggle>
          <UserDropdown logout={this.logout} {...this.props} />
        </UncontrolledDropdown>

        {this.state.modalSchedule &&
        <ModalSchedule modal={this.state.modalSchedule} handleToggleModal={this.handleToggleModal} />}
      </ul>
    )
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.login
  }
}

export default connect(
  mapStateToProps,
  {
    emptyLogin
  }
)(NavbarUser);
