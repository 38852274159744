//servico API
import { apiInstance } from "./api";
import { history } from "../history"
import { setJsonLocalStorage, getJsonLocalStorage } from "../utility/functions/Functions"

/**
 * Cancela a requisição atual
 *
 * @return {void}
 */
export const cancel = () => {
  this._source.cancel('Operação cancelada.');
};

export const passEncode = (pass) => {
  return btoa(pass + "Ab0fc1#19$ffc%&9527a@!41e4(64b)94(a459)");
}

/**
 * Busca id e token no Storage para ser utilizado nas requisicoes
 *
 * @return {json} [id e token para autenticacao]
 */
const getHeaders = async () => {
  let profile = { token: '', id: '' };

  profile = {
    ...profile,
    ...getJsonLocalStorage('@obr:profile')
  }

  return {
    'access-control-allow-origin': '*',
    'x-access-token': `${profile.token}`,
    'id-user': `${profile.id}`,
    'name-user': `${profile.name}`,
    'email-user': `${profile.email}`,
    'permission-user': `${JSON.stringify(profile.permission)}`,
    'role-user': `${JSON.stringify(profile.role)}`,
    'image-user': `${JSON.stringify(profile.image)}`,
    'type-user': `${profile.type}`,
    'organization': `${profile.organization}`,
    'hostname': `${window.location.hostname}`
  };
};

/**
 * Retorna os dados da API
 *
 * @param {string} model [nome do servico na api]
 * @param {int} id [id do registro a ser retornado]
 * @param {string} uri [url personalizada]
 * @return {json}
 */
export const get = async (model, id = undefined, keys = undefined) => {

  let headers = await getHeaders();
  if( keys ) headers = { ...headers, "keys": keys };
  const api = apiInstance(headers);

  let url = '/' + model;
  if (id) {
      url = url + '/' + id;
  }

  try {
      return await api.get(url);
  } catch (error) {
      if (error == 'Error: Request failed with status code 401' ) {
        history.push('/misc/not-authorized');
      }
  }

};

/**
 * Registra os dados na API
 *
 * @param {string} model [nome do servico na api]
 * @param {json} data [json com os dados para registrar]
 * @param {string} uri [url personalizada]
 * @return {json}
 */
export const post = async (model, data, keys = undefined) => {

  let headers = await getHeaders();
  if (keys) headers = { ...headers, "keys": keys };
  const api = apiInstance(headers);

  let url = '/' + model;

  try {
      return await api.post(url, data);
  } catch (error) {
    if (error == 'Error: Request failed with status code 401') {
      history.push('/misc/not-authorized');
    }
  }
};

/**
 * Atualiza os dados da API
 *
 * @param {string} model [nome do servico na api]
 * @param {json} data [json com os dados para registrar]
 * @param {int} id [id do registro a ser atualizado]
 * @param {string} uri [url personalizada]
 * @return {json}
 */
export const put = async (model, data, id = undefined, keys = undefined) => {

  let headers = await getHeaders();
  if (keys) headers = { ...headers, "keys": keys };
  const api = apiInstance(headers);

  let url = '/' + model;
  if (id) {
      url = url + '/' + id;
  }

  try {
      return await api.put(url, data);
  } catch (error) {
    if (error == 'Error: Request failed with status code 401') {
      history.push('/misc/not-authorized');
    }
  }
};

/**
 * Deleta os dados na API
 *
 * @param {string} model [nome do servico na api]
 * @param {int} id [id do registro para excluir]
 * @param {string} uri [url personalizada]
 * @return {json}
 */
export const exclude = async (model, id = undefined, keys = undefined) => {

  let headers = await getHeaders();
  if (keys) headers = { ...headers, "keys": keys };
  const api = apiInstance(headers);

  let url = '/' + model;
  url = url + '/' + id;

  try {
      return await api.delete(url);
  } catch (error) {
    if (error == 'Error: Request failed with status code 401') {
      history.push('/misc/not-authorized');
    }
  }
};
