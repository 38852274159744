import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import organization from "./organization/"
import account from "./account/"
import navbar from "./navbar/Index"

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  organization: organization,
  account: account,
  navbar: navbar
})

export default rootReducer
