import React from "react"
import {
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
  Card,
  CardBody,
  Table
} from "reactstrap"
import {
  List,
  Play,
  Rewind,
  Plus,
  Check,
} from "react-feather"
import {
  isBrowser, isTablet
} from "react-device-detect";
import NumberFormat from 'react-number-format'
import StatisticsCard from "../../components/@vuexy/statisticsCard/StatisticsCard"
import Select from "react-select"
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import Radio from "../../components/@vuexy/radio/RadioVuexy"
import messages from "../../utility/context/Messages.json"
import { X } from "react-feather"
import { connect } from "react-redux"
import moment from "moment"
import * as utility from '../../utility/functions/Functions'
import * as crud from '../../services/crud'

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.myTimeout = null;
  }

  state = {
    loading: false,
    loadingContext: false,
    loadingEvent: false,
    modalEvent: false,
    data: [],
    events: [],
    viewEvents: [],
    listTags: [],
    colors: ["#37898C", "#D9AA55", "#6AD5D9", "#8C4F29", "#D9956A", "#75A1D9", "#5FD998", "#D98880", "#96FCFF"],
    typeTag: 'team',
    selectedTag: -1
  }

  async componentDidMount() {
    this.getTags();
    this.getSchedules();
  }

  getSchedules = async () => {
    try {
      this.setState({ loading: true });

      let response = await crud.get('schedule/all');

      this.setState((state) => {
        let {
          events,
          viewEvents,
          loading,
        } = state;

        response.data.map((item, index) => {
          events.push({
            ...item,
            start: moment(item.start).utcOffset(0).format('YYYY-MM-DD'),
            end: moment(item.end).utcOffset(0).format('YYYY-MM-DD'),
          });

          viewEvents.push({
            ...item,
            start: moment(item.start).utcOffset(0).format('YYYY-MM-DD'),
            end: moment(item.end).utcOffset(0).add(1, 'day').format('YYYY-MM-DD'),
            backgroundColor: item.color,
            textColor: '#FFFFFF',
            borderColor: item.color
          });
        })

        loading = false;

        return {
          events,
          viewEvents,
          loading
        };
      });

    } catch (error) {

    }
  }

  getTags = async () => {
    try {
      if( this.state.typeTag == 'team' ) {
        await this.getTeams();
      }
    } catch (error) {

    }
  }

  handleToggleModalEvent = async (startAt = undefined, endAt = undefined, event = undefined, indexService = undefined) => {

    if( event ){

      this.state.events.map((item, index) => {
        if (item._id === event._id) {
          this.setState({
            modalEvent: !this.state.modalEvent,
            indexEvent: index
          });
        }
      })

    } else if (startAt, endAt) {

      let response = await crud.post('schedule', {
        description: '',
        start: startAt,
        end: endAt,
        team: this.state.listTags[this.state.selectedTag]._id,
        color: this.state.listTags[this.state.selectedTag].color,
        type: 'team',
      });

      if( response.status == 201 ) {

        this.setState({
          events: [
            ...this.state.events,
            {
              _id: response.data._id,
              description: '',
              start: startAt,
              end: endAt,
              team: this.state.listTags[this.state.selectedTag]._id,
              type: 'team',
              color: this.state.listTags[this.state.selectedTag].color,
            }
          ],
          viewEvents: [
            ...this.state.viewEvents,
            {
              _id: response.data._id,
              description: '',
              start: startAt,
              end: endAt,
              team: this.state.listTags[this.state.selectedTag]._id,
              type: 'team',
              backgroundColor: this.state.listTags[this.state.selectedTag].color,
              textColor: '#FFFFFF',
              borderColor: this.state.listTags[this.state.selectedTag].color
            }
          ]
        });

      }

    } else {
      this.setState({
        modalEvent: !this.state.modalEvent
      });
    }
  }

  handleInputs = async (index, newState, deleted = false) => {

    if (!!deleted) {

      await crud.exclude('schedule', this.state.events[index]._id);

    } else {

      clearTimeout(this.myTimeout);
      let data = ('description' in newState) ? { description: newState.description } : newState;
      data = { ...this.state.events[index], ...data };

      this.myTimeout = setTimeout(async () => {
        await crud.put('schedule', data, this.state.events[index]._id);
      }, 1500);

    }

    this.setState((state) => {
      let {
        events,
        viewEvents,
        modalEvent,
      } = state;

      if (!!deleted) {

        events.splice(index, 1);
        viewEvents.splice(index, 1);
        modalEvent = !modalEvent;

      } else {

        if (index != undefined && newState != undefined) {
          newState = ('description' in newState) ? { description: newState.description } : newState;
          events[index] = { ...events[index], ...newState };
          viewEvents[index] = { ...viewEvents[index], ...newState };
        }

      }

      return {
        events,
        viewEvents,
        modalEvent
      };
    });
  }

  changeEvent = async ( event, start, end ) => {
    const { viewEvents } = this.state
    const nextViewEvents = viewEvents.map(existingEvent => {
      return existingEvent._id === event._id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    end = moment(end).utcOffset(0).subtract(1, 'day').format('YYYY-MM-DD')
    const { events } = this.state
    const nextEvents = events.map(existingEvent => {
      return existingEvent._id === event._id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    this.setState({
      events: nextEvents,
      viewEvents: nextViewEvents
    })

    await crud.put('schedule', {
      ...event,
      start: start,
        end: end
    }, event._id);

  }

  getTeams = async () => {
    try {
      let response = await crud.get('parameter/status', 'team/approved', '_id,name,initials,color');
      if (response.status != 200) {
        this.props.handleAlert('messageAlert', messages.GET_PARAMETERS_ERROR);
        this.props.handleAlert('errorAlert', true);
        return;
      }

      this.setState({ listTags: response.data });
    } catch (error) {
      this.props.handleAlert('messageAlert', messages.GET_PARAMETERS_ERROR);
      this.props.handleAlert('errorAlert', true);
    }
  }

  Context() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modal}
          toggle={() => this.props.handleToggleModal()}
          className={`modal-dialog-centered modal-xl`}
          style={!isBrowser && !isTablet ? { maxWidth: 100 + '%', marginLeft: 0, marginRight: 0, marginTop: 15 } : {}}
        >
          <ModalHeader toggle={() => this.props.handleToggleModal()}>
            Calendário
          </ModalHeader>
          <ModalBody style={!isBrowser ? { padding: 6 } : {}}>
            {!this.state.loading ?
            <Row>
              <Col md="4" lg="4" xs="12">
                <Row>
                  <Col sm="12" lg="12">
                    <FormGroup>
                      <Input
                        type="select"
                        name="select"
                        id="typeTag"
                        className="custom-select"
                        required={true}
                        value={this.state.typeTag}
                        onChange={(e) => this.setState({ typeTag: e.target.value })}
                      >
                        <option value="team">EQUIPE</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="12" xs="12">
                    <Table>
                      <tbody>
                        {this.state.listTags.map((item, index) => (
                          <tr
                            style={{ cursor: "pointer", backgroundColor: index == this.state.selectedTag ? "#EEE" : "#FFF" }}
                            key={index}
                            onClick={() => this.setState({ selectedTag: this.state.selectedTag == index ? -1 : index })}
                          >
                            <td>
                              <div
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  borderRadius: "50%",
                                  display: "inline-block",
                                  marginLeft: 8,
                                  marginRight: 8,
                                  marginTop: 5,
                                  backgroundColor: item.color
                                }}
                              />
                            </td>
                            <td>{item.name}</td>
                            <td style={{ minWidth: 45 }}>{this.state.selectedTag == index && <Check size={17}/>}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
              <Col md="8" lg="8" xs="12">
                <FullCalendar
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  weekends={true}
                  timeZoneParam={'local'}
                  events={
                    !this.state.modalEvent
                      ? this.state.viewEvents.map((item) => {
                        return {
                          ...item,
                          title: item.description.toUpperCase()
                        }
                      })
                      : this.state.viewEvents}
                  locale={'pt-br'}
                  buttonText={{
                    today: 'Hoje',
                    month: 'Mês',
                    week: 'Semana',
                    day: 'Dia',
                    list: 'Lista'
                  }}
                  headerToolbar={
                    {
                      //left: 'dayGridMonth,dayGridWeek',
                      //center: 'title',
                      left: 'title',
                      right: 'prev,next'
                    }
                  }
                  /*datesSet={(dateInfo) => {
                    console.log(dateInfo.start) //start of the range the calendar date
                    console.log(dateInfo.end) //end of the range the calendar date
                  }}*/
                  eventDrop={(info) => this.changeEvent(info.event._def.extendedProps, info.event.startStr, info.event.endStr)}
                  eventClick={
                    (info) => {
                      this.handleToggleModalEvent(undefined, undefined, info.event._def.extendedProps)
                    }
                  }
                  editable={true}
                  dateClick={(info) => this.state.selectedTag != -1 && this.handleToggleModalEvent(info.dateStr, info.dateStr)}
                  eventResize={(info) => this.changeEvent(info.event._def.extendedProps, info.event.startStr, info.event.endStr)}
                />
              </Col>
            </Row> : <Spinner />}
          </ModalBody>
        </Modal>

        {this.state.modalEvent &&
        <Modal
          isOpen={this.state.modalEvent}
          toggle={() => this.handleToggleModalEvent()}
          className={`nav-justified modal-lg`}
        >
          <ModalHeader toggle={() => this.handleToggleModalEvent()}>
            Criar Evento
          </ModalHeader>
          <ModalBody style={!isBrowser ? { padding: 6 } : {}}>
            <Row>
              <Col lg="12" md="12" xs="12" className="text-right mt-1">
                <Button
                  className="p-0 mb-1"
                  color="link"
                  style={{ color: '#333' }}
                  onClick={() => this.handleInputs(this.state.indexEvent, undefined, true)}
                >
                  <X size="20" className="mb-1" /><br />
                  <span>Excl. Evento</span>
                </Button>
              </Col>
              <Col xs="12" sm="12" lg="12" md="12">
                <FormGroup>
                  <Label for="description">Descrição do Evento *</Label>
                  <Input
                    id="description"
                    type="textarea"
                    style={{ textTransform: 'uppercase', height: 150 }}
                    value={this.state.events[this.state.indexEvent].description}
                    onChange={(e) => this.handleInputs(this.state.indexEvent, { description: e.target.value })}
                    required={true} />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.handleToggleModalEvent()}>
              Ok
          </Button>
          </ModalFooter>
        </Modal>}
      </React.Fragment>
    )
  }

  render = () => <>{this.state.loadingContext ? <Spinner /> : this.Context()}</>
}

const mapStateToProps = state => {
  return {
    user: state.auth.login
  }
}

export default connect(mapStateToProps)(Schedule);
